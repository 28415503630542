import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import {
    getAttributesV2,
    GetAttributesV2Response,
} from 'waypoint-requests/attributes/getAttributesV2';

export const useGetAttributesV2 = (
    entityCodes: string[]
): DataHookResponse<GetAttributesV2Response> => {
    const { data, error, mutate, isValidating } = useSWR(
        [`/api/attributes/v2`, entityCodes],
        () => getAttributesV2(entityCodes),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        }
    );

    return {
        isLoading: !data,
        isError: !!error,
        isValidating,
        data,
        mutate,
    };
};
