import _ from 'lodash';
import {
    AttributeHashmap,
    SelectedAttributeFilter,
    SelectedAttributes,
    SelectOptionType,
} from 'components/app/global-filter-drawer/types/globalFilterTypes';
import {
    AttributeFilter,
    FilterReferenceTypes,
    SavedFilter,
} from 'contexts/saved-filters';
import { AttributeFromAPI } from 'contexts/entity-attributes';
import { createUUID } from 'waypoint-utils';
import { Entity } from 'waypoint-types';
import { Dictionary } from 'ts-essentials';

/** This utility simply creates a hashmap where filter uuids are keys and their values are a list of entities that pass that filter. It is used in other utils to funnel properties through a collection of filters and to produce another hashmap of available options for each attribute in the FilterEditor UI */
function getEntitiesByFilterHashmap(
    attributes: AttributeFromAPI[],
    entities: Entity[],
    currentSelections: SelectedAttributes,
) {
    const validFilters = getValidAttributeFilterSelections(currentSelections);

    const validFilterUUIDs = Object.keys(validFilters);

    if (!validFilterUUIDs) {
        return {};
    }

    return validFilterUUIDs.reduce(
        (
            filteredEntityHashmap: Dictionary<Entity[]>,
            uuid: string,
        ): Dictionary<Entity[]> => {
            const {
                attributeDataIndex,
                attributeValues,
            }: SelectedAttributeFilter = currentSelections[uuid];

            const funneledEntities: Entity[] = entities.filter(
                (ent: Entity) => {
                    const attributeValueForEntity = ent[attributeDataIndex];
                    if (Array.isArray(attributeValueForEntity)) {
                        return attributeValueForEntity.some((att) =>
                            attributeValues.includes(att),
                        );
                    }
                    return attributeValues.includes(attributeValueForEntity);
                },
            );

            filteredEntityHashmap[uuid] = funneledEntities;

            return filteredEntityHashmap;
        },
        {},
    );
}

// filter both previously selected attributes and
// attributes with no assigned values for entities
export function filterOutPreviouslySelectedOrUnassignedAttributes(
    currentSelections: SelectedAttributes,
    attributeLookup: AttributeHashmap,
    keysToRemove?: string[],
): SelectOptionType[] {
    const previouslySelectedAttributes = Object.values(currentSelections);

    const previouslySelectedAttributeNames: string[] =
        previouslySelectedAttributes
            ? previouslySelectedAttributes.map((attr) => attr.attribute)
            : [];

    return Object.keys(attributeLookup).reduce(
        (unSelectedAttributes: SelectOptionType[], attrName: string) => {
            if (
                !previouslySelectedAttributeNames.includes(attrName) &&
                !keysToRemove?.includes(attributeLookup[attrName].dataIndex) &&
                attributeLookup[attrName].options.length > 0
            ) {
                unSelectedAttributes.push({
                    label: attrName,
                    value: attributeLookup[attrName].dataIndex,
                });
            }
            return unSelectedAttributes;
        },
        [] as SelectOptionType[],
    );
}

/* Due to UX requirements, filters can be added to the UI without valid values in state (aka, they are empty). This util filters out empty/invalid filter state values. It's used in a couple of places but was primarily implemented so that invalid filters are never applied or sent to the API.
 */
export function getValidAttributeFilterSelections(
    currentSelections: SelectedAttributes,
) {
    const uuids = Object.keys(currentSelections);

    const validAttributeFilters = uuids.reduce(
        (validAttributeFilters, uuid): SelectedAttributes => {
            const attributeFilter = currentSelections[uuid];

            if (
                attributeFilter.attribute &&
                attributeFilter.attributeValues.length
            ) {
                validAttributeFilters[uuid] = attributeFilter;
            }

            return validAttributeFilters;
        },
        {} as SelectedAttributes,
    );

    return validAttributeFilters;
}

function createAttributeHashmap(
    attributes: AttributeFromAPI[],
    entities: Entity[],
): AttributeHashmap {
    return attributes.reduce(
        (attributeHashMap: AttributeHashmap, attr: AttributeFromAPI) => {
            attributeHashMap[attr.title] = {
                title: attr.title,
                dataIndex: attr.dataIndex,
                options: deriveAttributeValueOptions(attr, entities),
                isSelected: false,
            };
            return attributeHashMap;
        },
        {},
    );
}

/**
 * This util removes invalid selections. If a user amends filters, it could make selections below them invalid. Occurs if a user has added multiple filters and edits any up the funnel.
 */

export function removeInvalidAttributeSelections(
    attributes: AttributeFromAPI[],
    entities: Entity[],
    currentSelections: SelectedAttributes,
): SelectedAttributes {
    const uuids = Object.keys(currentSelections);

    const funneledAttributeHashmap = getFunneledAttributeHashmap(
        attributes,
        entities,
        currentSelections,
    );

    // for each uuid,
    return uuids.reduce((validSelections, uuid): SelectedAttributes => {
        const selection: SelectedAttributeFilter = currentSelections[uuid];
        // get attribute title
        // get the the selected values for that option
        const { attribute, attributeValues } = selection;

        // get the hash map for that filter
        const attributeHashmapForFilter = funneledAttributeHashmap[uuid];

        // use attribute title to lookup valid options for that uuid and attribute

        const validOptions: string[] =
            attribute && attribute in attributeHashmapForFilter
                ? attributeHashmapForFilter[attribute].options.map(
                      (option: SelectOptionType) => option.label,
                  )
                : [];

        // remove attributeValues that are not found in that list
        const validAttributeValues = attributeValues.filter((attr) =>
            validOptions.includes(`${attr}`),
        );

        // amend selections
        validSelections[uuid] = {
            ...selection,
            attributeValues: validAttributeValues,
        };

        return validSelections;
    }, {} as SelectedAttributes);
}

/** This returns an aray of possible options for any attribute. The options are derived directly from values found in the entity colleciton. */
export function deriveAttributeValueOptions(
    attr: AttributeFromAPI,
    entities: Entity[],
): SelectOptionType[] {
    const validAttributeValues = entities
        .flatMap((ent) => ent[attr.dataIndex])
        .filter((attributeValue) => attributeValue);
    const uniqueValidAttibuteValues = Array.from(new Set(validAttributeValues));

    return uniqueValidAttibuteValues.map((attributeValue) => ({
        value: `${attributeValue}`,
        label: `${attributeValue}`,
    }));
}

/** This returns an array of entity codes. Each entity code represents an entity that has passed every filter. Used for FilterEditor */
export function funnelEntities(
    attributes: AttributeFromAPI[],
    entities: Entity[],
    currentSelections: SelectedAttributes,
): Entity[] {
    const filteredEntitiesByFilter = getEntitiesByFilterHashmap(
        attributes,
        entities,
        currentSelections,
    );

    // NOTE: it is unclear why the apply and the requirement to pass the lodash _ dependency to this intersetion is necessary.
    return _.intersection.apply(
        _,
        Object.values(filteredEntitiesByFilter),
    ) as Entity[];
}

export function getFunneledAttributeHashmap(
    attributes: AttributeFromAPI[],
    entities: Entity[],
    currentSelections: SelectedAttributes,
) {
    // iterate over currentSelections
    const uuids = Object.keys(currentSelections);

    const collectionOfHashmaps = uuids.reduce((nestedHashmap, uuid, index) => {
        const indexOfSelection = uuids.indexOf(uuid);

        const uuidsAboveCurrent = [...uuids].slice(0, indexOfSelection);

        const selectionsAboveCurrent = uuidsAboveCurrent.reduce((hash, id) => {
            hash[id] = currentSelections[id];
            return hash;
        }, {} as Dictionary<SelectedAttributeFilter>);

        // if top filter or is edge case where all filters above are cleared (aka, invalid)...
        // ... default to all available attributes and options

        const allFiltersAboveAreInvalid =
            Object.keys(
                getValidAttributeFilterSelections(selectionsAboveCurrent),
            ).length === 0;
        if (uuidsAboveCurrent.length === 0 || allFiltersAboveAreInvalid) {
            nestedHashmap[uuid] = createAttributeHashmap(attributes, entities);
            return nestedHashmap;
        }

        // a list of entities that pass all filters above
        const funneledEntitiesForFilter = funnelEntities(
            attributes,
            entities,
            selectionsAboveCurrent,
        );
        // use funneled entities to create a new hashmap // TODO: remove previously selected attributes here
        const attributeHashmapForFilter = createAttributeHashmap(
            attributes,
            funneledEntitiesForFilter,
        );

        nestedHashmap[uuid] = attributeHashmapForFilter;

        return nestedHashmap;
    }, {} as Dictionary<AttributeHashmap>);

    return collectionOfHashmaps;
}

/** Applied filters in local storage have a different shape than filters in component state. This simply converts an applied filter to one consumable by the FilterEditor */
export function convertSavedFilterToSelectedAttributes(
    appliedFilter: SavedFilter | null,
): SelectedAttributes {
    if (!appliedFilter || !appliedFilter.filters) {
        return {};
    }
    const { filters } = appliedFilter;
    const converted = filters.reduce(
        (collection, filter): SelectedAttributes => {
            const uuid = createUUID();
            collection[uuid] = {
                uuid,
                attribute: filter.title,
                attributeDataIndex: filter.key,
                attributeValues: filter.values,
            };
            return collection;
        },
        {} as SelectedAttributes,
    );
    return converted;
}

/** Applied filters in local storage have a different shape than filters in component state. This simply converts an component (FilterEditor) state to the required local storage shape. */
export function convertSelectedAttributesToSavedFilter(
    attributeFilters: SelectedAttributes,
): SavedFilter {
    const validFilters = removeBlankAttributeFilreters(attributeFilters);

    const filterValues = Object.values(validFilters);

    const validFilterValues: AttributeFilter[] = filterValues
        ? filterValues.map((filter: SelectedAttributeFilter) => {
              return {
                  key: filter.attributeDataIndex,
                  title: filter.attribute,
                  values: filter.attributeValues,
              };
          })
        : [];

    const filterToApply: SavedFilter = {
        name: '',
        reference_type: FilterReferenceTypes.USER,
        filters: validFilterValues,
    };

    return filterToApply;
}

/** Removes filter fields that have empty values before they are applied. Defensive. It's a bit unlikely a user will try to apply a blank filter but it's possible  */
function removeBlankAttributeFilreters(
    attributeFilters: SelectedAttributes,
): SelectedAttributes {
    const uuids = Object.keys(attributeFilters);

    const submittableAttributeFilters = uuids.reduce(
        (submittableAttributeFilters, uuid): SelectedAttributes => {
            const attributeFilter = attributeFilters[uuid];
            if (
                attributeFilter.attribute &&
                attributeFilter.attributeValues.length
            ) {
                submittableAttributeFilters[uuid] = attributeFilter;
            }
            return submittableAttributeFilters;
        },
        {} as SelectedAttributes,
    );
    return submittableAttributeFilters;
}
