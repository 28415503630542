import React, {
    ReactElement,
    useContext,
    useEffect,
    useCallback,
    useState,
    useRef,
    MutableRefObject,
} from 'react';
import { orderBy, isEqual, debounce } from 'lodash';
import { Button, Divider, List, Input, Alert } from 'antd';
import { ListChildComponentProps } from 'react-window';
import { css, cx } from 'emotion';

import { DASH_DASH } from 'config/constants';
import theme from 'config/theme';
import {
    useGetSelectedEntityCodes,
    useGetFilteredEntityCodes,
} from 'waypoint-hooks';
import { Checkbox, CheckboxList } from 'waypoint-react';
import { EntityAttributesContext } from 'contexts';
import { clearQueryParam } from 'waypoint-hooks/useGetQueryParam';

const { Search } = Input;

const selectAllStyle = css`
    margin-top: 16px;
    margin-bottom: -12px;
`;

const applyButton = css`
    width: 100%;
    margin-top: 20px;
    color: ${theme.colors.white} !important;
    background-color: ${theme.colors.blues.primary} !important;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.7) !important;
    border-color: ${theme.colors.blues.primary} !important;
`;

const disabledButton = css`
    background-color: ${theme.colors.grays.disabled} !important;
    border-color: ${theme.colors.grays.disabled} !important;
`;

const propertyText = css`
    width: 39ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${theme.colors.blues.primary};
    display: block;
    @media only screen and (max-width: 1570px) {
        width: 28ch;
    }
    @media only screen and (max-width: 1291px) {
        width: 22ch;
    }
    @media only screen and (max-width: 1120px) {
        width: 19ch;
    }
    @media only screen and (max-width: 980px) {
        width: 15ch;
    }
`;

const LIST_HEIGHT: number = 300;

const LoadingState = () => {
    return (
        <>
            <Divider />
            <Search loading />
            <Divider />
            <div className={selectAllStyle} />
            <CheckboxList loading height={LIST_HEIGHT} />
            <Button
                loading
                type="primary"
                shape="round"
                size="large"
                className={applyButton}
            >
                Apply
            </Button>
        </>
    );
};

export const PropertyCheckboxList = (props: any) => {
    const { isDrawerOpen, setIsDrawerOpen } = props;

    useEffect(() => {
        if (isDrawerOpen) {
            setSelectedEntityCodes(
                new Set(_applied_selected_entityCodes ?? []),
            );
        }
    }, [isDrawerOpen]);

    const context = useContext(EntityAttributesContext);

    const [allSelected, setAllSelected] = useState<boolean>(true);

    const [searchText, setSearchText] = useState<string>('');

    const debouncedSearch = debounce(setSearchText, 300);

    // get selected_entity_codes key from storage
    const [_applied_selected_entityCodes, _setSelectedEntityCodes] =
        useGetSelectedEntityCodes();

    // get entities passing applied attribute filters
    const { filteredEntityCodes, filteredEntities } =
        useGetFilteredEntityCodes();

    // intitiate state
    const [selectedEntityCodes, setSelectedEntityCodes] = useState<Set<string>>(
        new Set(_applied_selected_entityCodes ?? []),
    );

    // sync state with local storage.
    useEffect(() => {
        setSelectedEntityCodes(new Set(_applied_selected_entityCodes ?? []));
        return () => {
            // sync when closing drawer so the UI is not stale
            setSelectedEntityCodes(
                new Set(_applied_selected_entityCodes ?? []),
            );
        };
    }, [_applied_selected_entityCodes]);

    // if filter changes, reset selected entity codes to filtered entity codes. useRef and useCallback to avoid infinite loop re-render
    const filteredEntityCodesRef: MutableRefObject<string[]> =
        useRef<string[]>(filteredEntityCodes);

    const applySelectedEntityCodesToLocalStorage = useCallback(
        (entityCodes) => {
            _setSelectedEntityCodes(entityCodes);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    // reconcile available entity codes from API with selected entity codes. local storage could be stale and properties could have been deleted.
    useEffect(() => {
        if (!context?.data) {
            return;
        }

        if (!isEqual(filteredEntityCodesRef.current, filteredEntityCodes)) {
            if (
                filteredEntityCodesRef.current.length ||
                !selectedEntityCodes.size
            ) {
                applySelectedEntityCodesToLocalStorage(filteredEntityCodes);
            }
            filteredEntityCodesRef.current = filteredEntityCodes;
        }

        // entities from API that have been passed through filters
        const availableEntityCodes = new Set<string>(filteredEntityCodes);

        const invalidEntityCodes = new Set<string>();

        for (const entityCode of selectedEntityCodes) {
            if (!availableEntityCodes.has(entityCode)) {
                invalidEntityCodes.add(entityCode);
            }
        }

        // Local storage could be stale and contain entities that have been deleted in database between sessions. Remove them here.
        if (invalidEntityCodes.size > 0) {
            const updatedSelection = new Set(
                [...selectedEntityCodes].filter(
                    (x) => !invalidEntityCodes.has(x),
                ),
            );

            setSelectedEntityCodes(updatedSelection);
        }

        // reset state
        setAllSelected(selectedEntityCodes.size === availableEntityCodes.size);
    }, [context, selectedEntityCodes, filteredEntityCodes]);

    if (context?.isAttributesLoading ?? true) {
        return <LoadingState />;
    }

    if (!context?.data?.entities) {
        return <span>No properties loaded</span>;
    }

    const filteredEntitiesPassingSearch: any[] =
        searchText.trim().length > 0
            ? filteredEntities.filter(
                  (s) =>
                      `${s.name}`
                          .toLowerCase()
                          .includes(searchText.toLowerCase().trim()) ||
                      `${s.entity_display_code}`
                          .toLowerCase()
                          .includes(searchText.toLowerCase().trim()),
              )
            : filteredEntities;

    const sortedProperties = orderBy(
        filteredEntitiesPassingSearch,
        [(x) => selectedEntityCodes.has(x.entity_code), 'name'],
        ['desc', 'asc'],
    );

    const setAllToState = (selected: boolean): void => {
        if (!selected) {
            // clear all selected
            setSelectedEntityCodes(new Set());
        } else {
            setSelectedEntityCodes(
                new Set(filteredEntities.map((x) => `${x.entity_code}`) ?? []),
            );
        }
    };

    function renderCheckbox<T>({
        index,
        style,
    }: ListChildComponentProps<T>): ReactElement {
        const item = sortedProperties[index];
        if (!item) {
            return <span>{DASH_DASH}</span>;
        }

        return (
            <List.Item key={index} style={style}>
                <Checkbox
                    checked={selectedEntityCodes.has(item.entity_code)}
                    onChange={() => {
                        let updatedEntityCodes = new Set(selectedEntityCodes);
                        if (selectedEntityCodes.has(item.entity_code)) {
                            updatedEntityCodes.delete(item.entity_code);
                        } else {
                            updatedEntityCodes.add(item.entity_code);
                        }

                        setSelectedEntityCodes(updatedEntityCodes);
                    }}
                >
                    <span
                        title={`${item.name} | (${item.entity_display_code})`}
                        className={propertyText}
                    >
                        {item.name} | ({item.entity_display_code})
                    </span>
                </Checkbox>
            </List.Item>
        );
    }

    return (
        <>
            <Search
                data-testid="search-properties-input"
                placeholder="Search properties"
                onChange={(event) => debouncedSearch(event.target.value)}
                style={{ width: '100%' }}
                allowClear
            />
            <div className={selectAllStyle}>
                <label>
                    <Checkbox
                        checked={allSelected}
                        onChange={(ev) => setAllToState(ev.target.checked)}
                    >
                        <span>Select all</span>
                    </Checkbox>
                </label>
                <span style={{ float: 'right' }}>
                    ({selectedEntityCodes.size}/{filteredEntities.length}{' '}
                    selected)
                </span>
            </div>
            {selectedEntityCodes?.size === 0 && (
                <Alert
                    style={{ marginTop: '10px' }}
                    message="Must select at least one property"
                    type="error"
                />
            )}
            <Divider />
            <CheckboxList
                height={props.height}
                renderCheckbox={renderCheckbox}
                items={sortedProperties}
            />
            <Button
                data-testid="apply-selections-button"
                shape="round"
                size="large"
                className={
                    selectedEntityCodes?.size === 0
                        ? cx([applyButton, disabledButton])
                        : applyButton
                }
                disabled={selectedEntityCodes?.size === 0}
                onClick={() => {
                    applySelectedEntityCodesToLocalStorage([
                        ...selectedEntityCodes,
                    ]);
                    setIsDrawerOpen(false);
                    clearQueryParam('comment_id');
                }}
            >
                Apply Selections
            </Button>
        </>
    );
};
