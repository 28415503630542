import React, { useContext } from 'react';
import { css } from 'emotion';
import { Select } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import theme from 'config/theme';
import { stringSort } from 'utils/tables/sorters';
import { EntityAttributesContext } from 'contexts/entity-attributes/EntityAttributesContext';
import {
    SelectedAttributes,
    SelectOptionType,
    HandleUpdateFilterAttributeType,
} from 'components/app/global-filter-drawer/types/globalFilterTypes';
import {
    getFunneledAttributeHashmap,
    filterOutPreviouslySelectedOrUnassignedAttributes,
} from 'components/app/global-filter-drawer/globalFilterUtils';

const filterContainerStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 6px 0;
    .fa {
        margin-top: 18px;
        cursor: pointer;
        &:hover {
            color: ${theme.colors.blues.primary};
        }
    }
`;

const labelStyle = css`
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.5);
    display: block;
`;

const disabledIconStyle = css`
    cursor: not-allowed !important;
`;

interface AttributeFilterFieldProps {
    uuid: string;
    disabled?: boolean;
    currentSelections: SelectedAttributes;
    handleRemoveAttributeFilter: (id: string) => void;
    handleUpdateAttributeFilter: HandleUpdateFilterAttributeType;
}

const AttributeFilterField = ({
    uuid,
    disabled,
    currentSelections,
    handleRemoveAttributeFilter,
    handleUpdateAttributeFilter,
}: AttributeFilterFieldProps): JSX.Element => {
    const entityAttributesContext = useContext(EntityAttributesContext);

    const {
        attribute: selectedAttribute,
        attributeValues: selectedAttributeValues,
        attributeDataIndex: selectedAttributeDataIndex,
    } = currentSelections[uuid];

    const filterByList = entityAttributesContext?.data
        ? entityAttributesContext?.data.groupableAndFilterableAttributes.filter(
              (el) => el.filterBy,
          )
        : [];

    const attributes = entityAttributesContext?.data
        ? entityAttributesContext.data.attributes.filter((el) =>
              filterByList.some((f) => f.attributeCode === el.key),
          )
        : [];

    const entities = entityAttributesContext?.data
        ? entityAttributesContext.data.entities
        : [];

    const funneledAttributeHashmap = getFunneledAttributeHashmap(
        attributes,
        entities,
        currentSelections,
    );

    const attributeHashmapForThisFilter = funneledAttributeHashmap[uuid];

    const attributeOptions = filterOutPreviouslySelectedOrUnassignedAttributes(
        currentSelections,
        attributeHashmapForThisFilter,
        ['name'], // this removes 'name' attribute from available options
    );

    const attributeValueOptions: SelectOptionType[] =
        attributeHashmapForThisFilter[selectedAttribute]
            ? attributeHashmapForThisFilter[selectedAttribute].options
            : [];

    return (
        <div
            data-testid="filter-attribute-inputs"
            className={filterContainerStyle}
        >
            <div style={{ width: '100%', marginRight: 12 }}>
                <label className={labelStyle}>Filter</label>
                <Select
                    style={{ width: '100%' }}
                    dropdownStyle={{ zIndex: 399999 }}
                    showSearch
                    value={selectedAttribute}
                    disabled={disabled ?? false}
                    onChange={(value: string, option: any) => {
                        handleUpdateAttributeFilter({
                            uuid,
                            attribute: option.label,
                            attributeDataIndex: option.value,
                            attributeValues: [],
                        });
                    }}
                    options={attributeOptions.sort((a, b) =>
                        stringSort(b?.label, a?.label),
                    )}
                />
            </div>
            <div style={{ width: '100%', marginRight: 12 }}>
                <label className={labelStyle}>Values</label>
                <Select
                    style={{ width: '100%' }}
                    mode="multiple"
                    maxTagCount="responsive"
                    dropdownStyle={{ zIndex: 399999 }}
                    maxTagTextLength={8}
                    value={selectedAttributeValues.map((attr) => `${attr}`)}
                    allowClear
                    disabled={disabled}
                    showSearch
                    onChange={(value: string[]) => {
                        handleUpdateAttributeFilter({
                            uuid,
                            attribute: selectedAttribute,
                            attributeDataIndex: selectedAttributeDataIndex,
                            attributeValues: value,
                        });
                    }}
                    options={attributeValueOptions.sort((a, b) =>
                        stringSort(b?.label, a?.label),
                    )}
                />
            </div>
            <DeleteTwoTone
                disabled={disabled}
                style={{ fontSize: 20, marginTop: 25 }}
                className={disabled ? disabledIconStyle : ''}
                twoToneColor={
                    !disabled ? theme.colors.red : theme.colors.grays.disabled
                }
                onClick={() => {
                    if (disabled) return;
                    handleRemoveAttributeFilter(uuid);
                }}
            />
        </div>
    );
};

export default AttributeFilterField;
