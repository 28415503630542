import { CameraOutlined } from '@ant-design/icons';
import { Col, Input, Row, Switch, Select as SelectAntDesign } from 'antd';
import { sortBy, toArray } from 'lodash';
import { useEffect, useMemo, useState, useContext } from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { css } from 'emotion';
import { useDebounce, useMediaQuery } from 'usehooks-ts';
import { getLeasesPerformanceOverview } from 'waypoint-requests';
import useSWR from 'swr';

import { selectProperties } from 'state/properties/selectors';
import { useGetSelectedFilteredEntityCodes } from 'waypoint-hooks';
import { PropertyDetailsProps, PropertyType } from 'waypoint-types';
import theme from 'config/theme';

import PropertyMap from './PropertyMap';
import { calculatePropertyBounds, isValidCoordinate } from './PropertyMapUtils';
import { PropertyMapMarker } from './PropertyMapMarker';
import PropertySearchListView from './PropertySearchListView';
import { Select } from 'waypoint-react';
import { useGetGroupableAttributes } from 'waypoint-hooks/useGetGroupableAttributes';
import { stringSort } from 'utils/tables/sorters';
import { EntityAttributesContext } from 'contexts';
import { markerColor } from './constants';
import PropertyOption from './PropertyOption';
import PropertyTag from './CustomTagProperty';

const propertyListPhotoToggler = css`
    &.ant-switch-checked {
        background-color: ${theme.colors.blues.antBlue};
    }
`;

const { Option, OptGroup } = SelectAntDesign;

const attributesSelector = css`
    display: flex;
    position: fixed;
    top: 70px;
    right: 27%;
    display: flex;
    flex-direction: column;
    z-index: 2;
`;

declare global {
    interface Window {
        ___GOOGLE_API_KEY: string;
    }
}

interface PropertySearchProps {
    properties: PropertyType[];
}

interface AttributeAndColorIndexType {
    attribute: string;
    colorIndex: number;
}

const TOPBAR_PX = 50;
const TOP_COLOR_MARKER_COUNT = markerColor.length - 1;
const COLOR_MARKER_OTHER = markerColor.length;

function PropertySearch({ properties }: PropertySearchProps): JSX.Element {
    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();
    const entityAttributesContext = useContext(EntityAttributesContext);

    const isDesktop = useMediaQuery('(min-width: 768px)');

    const [searchedEntities, setSearchedEntities] = useState<PropertyType[]>(
        [],
    );

    const { data: leasesDataFetchedData } = useSWR(
        '/api/leases/performance-overview',
        () =>
            getLeasesPerformanceOverview(entityCodes, {
                percentageType: null,
                stakeholder: null,
            }),
        {
            revalidateOnFocus: true,
            revalidateOnMount: true,
        },
    );

    const [showPropertyPhotos, setShowPropertyPhotos] = useState(false);
    const [showGroupByLegends, setShowGroupByLegends] = useState(false);
    const [groupByAttribute, setGroupByAttribute] = useState('');
    const [selectedAttributes, setSelectedAttributes] = useState<string[]>([]);
    const [groupedEntities, setGroupedEntities] = useState<string[][]>();
    const [leasesData, setLeasesData] = useState<
        PropertyDetailsProps[] | undefined
    >([]);

    const [selectedPropertyId, setSelectedPropertyId] = useState<
        string | undefined
    >();

    const [searchText, setSearchText] = useState<string>('');

    const debouncedFilteredProperties = useDebounce(searchText, 100);

    useEffect(() => {
        setLeasesData(leasesDataFetchedData);
    }, [leasesDataFetchedData, leasesData]);

    useEffect(() => {
        const searchTerm = debouncedFilteredProperties
            .trim()
            .toLocaleLowerCase();

        const entitiesFilteredByEntitiesCode: PropertyType[] =
            properties.filter((e) => entityCodes.includes(e.id));

        const matchingProperties = entitiesFilteredByEntitiesCode.filter(
            (e) =>
                e.display_name?.toLocaleLowerCase().includes(searchTerm) ||
                e.display_code?.toLocaleLowerCase().includes(searchTerm) ||
                e.asset_type_name?.toLocaleLowerCase().includes(searchTerm) ||
                e.street_address?.toLocaleLowerCase().includes(searchTerm) ||
                e.country_code?.toLocaleLowerCase().includes(searchTerm) ||
                e.state_abbr?.toLocaleLowerCase().includes(searchTerm) ||
                e.city?.toLocaleLowerCase().includes(searchTerm),
        );

        const filteredSearchedEntities = sortBy(
            searchTerm.length > 0
                ? matchingProperties
                : entitiesFilteredByEntitiesCode,
            ['display_name'],
        );
        setSearchedEntities(filteredSearchedEntities);

        setGroupedEntities(
            groupEntitiesByAttribute(
                filteredSearchedEntities,
                groupByAttribute,
            ),
        );
    }, [properties, debouncedFilteredProperties, entityCodes]);

    const handleSearch = (text: string) => {
        setSearchText(text);
    };

    const groupedEntries = Object.entries(groupedEntities ?? []);

    const keysOfEntries = groupedEntries.map((entry) => entry[0]);

    const attributesGroupedForSelectFilter = groupedEntries.sort(
        (a, b) => b[1].length - a[1].length,
    );

    const legendsItems: string[] = selectedAttributes.filter(
        (itemLegend) => keysOfEntries.indexOf(itemLegend) > -1,
    );

    const selectedEntries = groupedEntries
        .map((item) => {
            if (legendsItems.find((entry) => entry === item[0])) {
                return item;
            }

            return undefined;
        })
        .filter((item) => item);

    const legendAndItems = selectedEntries.length
        ? selectedEntries
        : groupedEntries.sort((a, b) => b[1].length - a[1].length);

    const flatEntitiesCodes: (string | undefined)[] = legendAndItems
        .map((item) => item && item[1])
        .flat();

    const searchedMapEntities = useMemo(
        () =>
            searchedEntities
                .filter((e) => isValidCoordinate(e.latitude, e.longitude))
                .filter((e) => flatEntitiesCodes.includes(e.id)),
        [searchedEntities, flatEntitiesCodes],
    );

    const searchBounds = useMemo(
        () =>
            calculatePropertyBounds(
                searchedMapEntities.map((x) => ({
                    lat: x.latitude,
                    lng: x.longitude,
                })),
            ),
        [searchedMapEntities],
    );

    const actualHeight = Math.max(window.innerHeight - TOPBAR_PX, 0);

    const groupableAttributes = useGetGroupableAttributes();
    const attributeOptions = groupableAttributes
        .map((attribute) => ({
            value: attribute.dataIndex,
            label: attribute.title,
        }))
        .sort((a, b) => stringSort(b?.label, a?.label));

    const groupEntitiesByAttribute = (
        entitiesToLookup: PropertyType[],
        attribute: string,
    ) => {
        const lookupSearchedEntities =
            entityAttributesContext?.data?.entities.filter(
                (entity) =>
                    !!entitiesToLookup.filter(
                        (lookup) => lookup.id === entity.entity_code,
                    ).length,
            );
        return (lookupSearchedEntities ?? []).reduce((result: any, item) => {
            if (!item[attribute]) {
                item[attribute] = 'Unassigned';
            }
            return {
                ...result,
                [item[attribute]]: [
                    ...(result[item[attribute]] || []),
                    item['entity_code'],
                ],
            };
        }, []);
    };

    const getColorIndex = (indexOfEntityGroup: number) => {
        return indexOfEntityGroup <= TOP_COLOR_MARKER_COUNT
            ? indexOfEntityGroup
            : COLOR_MARKER_OTHER;
    };

    const getAttributeAndColorByEntityCode = (
        entityCode: string,
    ): AttributeAndColorIndexType => {
        const sortedEntityGroups = Object.entries(groupedEntities ?? []).sort(
            (a, b) => b[1].length - a[1].length,
        );

        const defaultResult = {
            attribute: '',
            colorIndex: 0,
        };

        if (!groupByAttribute) {
            return defaultResult;
        }

        if (!sortedEntityGroups.length) {
            return defaultResult;
        }

        const indexOfEntityGroup = sortedEntityGroups.findIndex(
            (group, _index) => {
                return group[1].find((code) => code === entityCode);
            },
        );

        if (indexOfEntityGroup === -1) {
            return defaultResult;
        }

        const entityGroupWithEntity = sortedEntityGroups[indexOfEntityGroup];

        return {
            attribute:
                indexOfEntityGroup <= TOP_COLOR_MARKER_COUNT
                    ? entityGroupWithEntity[0]
                    : 'Other',
            colorIndex: getColorIndex(indexOfEntityGroup),
        };
    };

    const onAttributeChanged = (newAttribute: string | undefined) => {
        setShowGroupByLegends(!(newAttribute === undefined));
        setGroupByAttribute(newAttribute ?? '');
        setGroupedEntities(
            groupEntitiesByAttribute(searchedEntities, newAttribute ?? ''),
        );
    };

    const convertGroupedToOptions = (
        options: [string, string[]][],
        notTop15: boolean = true,
    ) => {
        return options.map((option) => ({
            value: option[0],
            length: option[1].length,
            color: notTop15
                ? markerColor[getColorIndex(15)]
                : markerColor[getColorIndex(options.indexOf(option))],
        }));
    };

    const topOptionsDropdown = convertGroupedToOptions(
        attributesGroupedForSelectFilter.slice(0, 15),
        false,
    );
    const otherOptionsDropdown = convertGroupedToOptions(
        attributesGroupedForSelectFilter.slice(
            15,
            attributesGroupedForSelectFilter.length,
        ),
    );

    return (
        <Row
            style={{
                position: 'absolute',
                inset: 0,
                padding: 0,
            }}
            wrap={false}
            gutter={0}
            data-testid={'property-search-wrapper'}
        >
            {isDesktop && (
                <Col span={18} flex="auto">
                    <div className={attributesSelector}>
                        <strong>Segment By</strong>
                        <Select
                            data-testid="segmentByValueSelect"
                            style={{ width: 250 }}
                            options={attributeOptions}
                            placeholder={'Select an attribute'}
                            allowClear
                            showSearch
                            onChange={(newGroupByValue: string) => {
                                onAttributeChanged(newGroupByValue);
                                setSelectedAttributes([]);
                            }}
                        />
                        {showGroupByLegends && (
                            <SelectAntDesign
                                data-testid="segmentByValueSelect"
                                mode="multiple"
                                style={{ width: 250, marginTop: 6 }}
                                value={selectedAttributes}
                                onChange={(values) =>
                                    setSelectedAttributes(values)
                                }
                                tagRender={PropertyTag}
                                showArrow
                                placeholder="All"
                                optionLabelProp="label"
                                allowClear
                            >
                                <OptGroup label="Top 15 by Property Count">
                                    {topOptionsDropdown.map((option) => (
                                        <Option
                                            value={option.value}
                                            label={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            marginRight: 6,
                                                            background:
                                                                option.color,
                                                            borderRadius: '50%',
                                                            width: '8px',
                                                            height: '8px',
                                                        }}
                                                    >
                                                        &nbsp;
                                                    </div>
                                                    {option.value} (
                                                    {option.length})
                                                </div>
                                            }
                                        >
                                            <PropertyOption
                                                key={option.value}
                                                option={option}
                                            />
                                        </Option>
                                    ))}
                                </OptGroup>
                                {attributesGroupedForSelectFilter.length >
                                    15 && (
                                    <OptGroup label="Other">
                                        {otherOptionsDropdown.map((option) => (
                                            <Option
                                                value={option.value}
                                                label={
                                                    <>
                                                        <div
                                                            style={{
                                                                marginRight: 6,
                                                                background:
                                                                    option.color,
                                                                borderRadius:
                                                                    '50%',
                                                                width: '8px',
                                                                height: '8px',
                                                            }}
                                                        >
                                                            &nbsp;
                                                        </div>
                                                        {option.value} (
                                                        {option.length})
                                                    </>
                                                }
                                            >
                                                <PropertyOption
                                                    key={option.value}
                                                    option={option}
                                                />
                                            </Option>
                                        ))}
                                    </OptGroup>
                                )}
                            </SelectAntDesign>
                        )}
                    </div>
                    <PropertyMap
                        bounds={searchBounds}
                        streetViewControl={false}
                        fullscreenControl={false}
                        style={{
                            height: `${actualHeight}px`,
                            width: '100%',
                        }}
                        mapTypeControlOptions={{
                            mapTypeIds: [
                                'roadmap',
                                'terrain',
                                'satellite',
                                'hybrid',
                                'grayscale',
                            ],
                        }}
                        data-testid={'property-search-map'}
                    >
                        {searchedMapEntities.map((prop) => {
                            const attributeAndColor: AttributeAndColorIndexType =
                                getAttributeAndColorByEntityCode(prop.id);
                            const tooltipTitleAttribute =
                                attributeAndColor.attribute !== ''
                                    ? `(${attributeAndColor.attribute})`
                                    : '';
                            return (
                                <PropertyMapMarker
                                    key={prop.id}
                                    onClick={() =>
                                        setSelectedPropertyId(prop.id)
                                    }
                                    title={`${prop.name} ${tooltipTitleAttribute}`}
                                    position={{
                                        lat: prop.latitude,
                                        lng: prop.longitude,
                                    }}
                                    icon={{
                                        path: google.maps.SymbolPath.CIRCLE,
                                        fillColor:
                                            markerColor[
                                                attributeAndColor.colorIndex
                                            ],
                                        fillOpacity: 1,
                                        scale: 7,
                                        strokeColor: 'white',
                                        strokeOpacity: 1,
                                        strokeWeight: 1,
                                    }}
                                />
                            );
                        })}
                    </PropertyMap>
                </Col>
            )}
            <Col
                span={isDesktop ? 6 : 24}
                style={{
                    height: '100%',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        padding: '10px 6px',
                        borderBottom: 'solid 1px #d1d1d1',
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <div>
                            <h2
                                style={{
                                    color: 'rgba(0, 0, 0, 0.85)',
                                    fontSize: '18px',
                                }}
                            >
                                Property List
                            </h2>
                        </div>
                        <div style={{ flex: 1, textAlign: 'right' }}>
                            <Switch
                                checkedChildren={<CameraOutlined />}
                                unCheckedChildren={<CameraOutlined />}
                                onChange={setShowPropertyPhotos}
                                defaultChecked={false}
                                className={propertyListPhotoToggler}
                                data-testid={'property-search-photo-toggle'}
                            />
                        </div>
                    </div>
                    <Input
                        addonBefore={`Search`}
                        allowClear
                        value={searchText}
                        onChange={(e: { target: HTMLInputElement }) => {
                            const { value } = e.target;
                            handleSearch(value);
                        }}
                        data-testid={'property-search-text'}
                    />
                </div>
                <div
                    style={{
                        flexGrow: 1,
                        overflowY: 'scroll',
                        padding: '0 6px',
                        paddingBottom: '10px',
                        backgroundColor: theme.colors.white,
                    }}
                    data-testid={'property-search-list'}
                >
                    <PropertySearchListView
                        properties={searchedEntities.filter((e) =>
                            flatEntitiesCodes.includes(e.id),
                        )}
                        showPropertyPhotos={showPropertyPhotos}
                        selectedPropertyId={selectedPropertyId}
                        leasesData={leasesData}
                    />
                </div>
            </Col>
        </Row>
    );
}

export function mapState(state: RootStateOrAny) {
    const isLoadingProperties = state.user.isLoadingProperties;
    const properties = toArray(selectProperties(state));

    return {
        isLoadingProperties,
        properties,
    };
}

export default withRouter(connect(mapState)(PropertySearch));
