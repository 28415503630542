import { useContext, useEffect, useMemo } from 'react';
import { css } from 'emotion';
import { useGetSelectedFilteredEntityCodes } from 'waypoint-hooks';
import { GRAY } from 'config/colors';
import useSWR from 'swr';
import { getAttributes } from 'waypoint-requests';
import {
    AppFeaturePermissions,
    EntityAttributesContext,
    EntityAttributesContextType,
} from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { AttributesEditableCard } from '../card/AttributesEditableCard';
import { useGetAttributesV2 } from 'waypoint-hooks/data-access/useGetAttributesV2';
import { Entity } from 'waypoint-types';
import { withRouter } from 'react-router';
import { connect, RootStateOrAny } from 'react-redux';
import { hasAttributesV2Enabled } from 'state/user/selectors';
import { AttributesCard } from '../card/AttributesCard';
import { Skeleton } from 'antd';

const pageStyle = css`
    display: block;
    background-color: ${GRAY};
    width: 100%;
`;

export interface AttributesDashboardProps {
    hasAttributesV2Enabled: boolean;
}

const AttributesDashboard = ({
    hasAttributesV2Enabled,
}: AttributesDashboardProps): JSX.Element => {
    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();
    const entityContext = useContext<EntityAttributesContextType | null>(
        EntityAttributesContext,
    );

    const { data, error } = useSWR('/api/attributes', getAttributes, {
        revalidateOnFocus: false,
        revalidateOnMount: true,
    });

    const {
        data: attributesDataV2,
        mutate,
        isLoading,
    } = useGetAttributesV2(entityCodes);

    useEffect(() => {
        entityContext?.mutateProviderAttributes();
    }, [attributesDataV2]);

    const entities = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.entities.filter(
            (x) =>
                typeof x.entity_code === 'string' &&
                entityCodes.includes(x.entity_code),
        );
    }, [data, entityCodes]);

    const attributesV2Entities = useMemo(() => {
        if (!data) {
            return [];
        }

        return attributesDataV2?.entities.filter(
            (entity: Partial<Entity>) =>
                typeof entity.entityCode === 'string' &&
                entityCodes.includes(entity.entityCode),
        );
    }, [attributesDataV2, entityCodes]);

    if (isLoading) {
        return (
            <Skeleton
                paragraph={{
                    rows: 10,
                }}
            />
        );
    }

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.Attributes}
            showDisabledView={true}
        >
            <div className={pageStyle}>
                {hasAttributesV2Enabled ? (
                    <AttributesEditableCard
                        key="attributes-card"
                        attributes={
                            attributesDataV2?.attributeDefinitions ?? []
                        }
                        entities={attributesV2Entities ?? []}
                        mutateAttributes={mutate}
                        error={error}
                        entityCodes={entityCodes}
                        isLoading={!data && !error}
                    />
                ) : (
                    <AttributesCard
                        key="attributes-card"
                        attributes={{
                            data: entities,
                            columns: data?.attributes ?? [],
                        }}
                        error={error}
                        isLoading={!data && !error}
                    />
                )}
            </div>
        </PermissionedWrapper>
    );
};

const mapState = (state: RootStateOrAny) => ({
    hasAttributesV2Enabled: hasAttributesV2Enabled(state),
});

export default withRouter(connect(mapState)(AttributesDashboard));
