import React, { useContext, useCallback, useState, useEffect } from 'react';
import {
    CROSSTAB_DEFAULT_PRIMARY_MODE,
    CROSSTAB_DEFAULT_SECONDARY_MODE,
    CROSSTAB_PRIMARY_MODE,
    CROSSTAB_SECONDARY_MODE,
} from './CrosstabConstants';
import { Moment } from 'moment';
import { useGetCrosstabAsOfDate } from './hooks';
import {
    AppFeaturePermissions,
    EntityAttributesContext,
    EntityAttributesContextType,
} from 'contexts';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import isEqual from 'react-fast-compare';
import { Dictionary } from 'ts-essentials';
import {
    useGetClientModes,
    useGetSelectedFilteredEntityCodes,
    useSelectedDataLevel,
} from 'waypoint-hooks';
import { getCrosstabData } from 'waypoint-requests';
import { DataRecord } from './CrosstabTypes';
import { getExpandedCrosstabRows } from './utils';
import { useGetCrosstabAccountTree } from './hooks';
import { CrosstabAccount, CrosstabAccountData } from './accounts';
import { useGetGroupableAttributes } from 'waypoint-hooks/useGetGroupableAttributes';
import {
    FIELD_ATTRIBUTE,
    FIELD_PERIOD,
    MEASURE_FIELD_VARIANCE_$,
} from './CrosstabConstants';
import { getCrosstabGridFields } from './utils/getCrosstabGridFields';
import { ClientMode, Entity } from 'waypoint-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { CrosstabCard } from './CrosstabCard';

const getDataSource = (
    rows: DataRecord[],
    rootAccount: CrosstabAccount,
    attributeSelected: string | null,
    visibleFields: Dictionary<boolean>,
    modeSelection: string[],
    clientModes: ClientMode[],
): PivotGridDataSource => {
    const fields = getCrosstabGridFields(
        rootAccount,
        attributeSelected,
        visibleFields,
        modeSelection,
        clientModes,
    );
    return new PivotGridDataSource({
        fields,
        store: rows,
        retrieveFields: false,
    });
};

export const CrosstabContainer = (): JSX.Element => {
    const [periodRange, setPeriodRange] = useState<[Moment, Moment] | null>(
        null,
    );
    const [isOpenSettings, setIsOpenSettings] = useState<boolean>(false);
    const [crosstabRows, setCrosstabRows] = useState<DataRecord[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [dataStore, setDataStore] = useState<PivotGridDataSource | null>(
        null,
    );
    const [modeSelection, setModeSelection] = useState<string[]>([
        CROSSTAB_DEFAULT_PRIMARY_MODE,
        CROSSTAB_DEFAULT_SECONDARY_MODE,
    ]);

    const [isFieldVisible, setIsFieldVisible] = useState<Dictionary<boolean>>({
        [CROSSTAB_PRIMARY_MODE]: true,
        [CROSSTAB_SECONDARY_MODE]: true,
        [MEASURE_FIELD_VARIANCE_$]: true,
        [FIELD_ATTRIBUTE]: false,
        [FIELD_PERIOD]: true,
        _variance_percent: true,
    });

    const [loadingError, setLoadingError] = useState<string | null>(null);
    const [crosstabAccountData, setCrosstabAccountData] = useState<
        CrosstabAccountData[] | null
    >(null);
    const [propertyNamesByEntityCode, setPropertyNamesByEntityCode] =
        useState<Dictionary<string> | null>(null);
    const [
        groupableAttributesByEntityCode,
        setGroupableAttributesByEntityCode,
    ] = useState<Dictionary<string> | null>(null);

    const groupableAttributes = useGetGroupableAttributes();
    const [attributeSelection, setAttributeSelection] = useState<string | null>(
        groupableAttributes[0]?.dataIndex,
    );

    const entityAttributesContext =
        useContext<EntityAttributesContextType | null>(EntityAttributesContext);

    const entityCodes = useGetSelectedFilteredEntityCodes();
    const { asOfDate } = useGetCrosstabAsOfDate(entityCodes);

    const {
        accountNamesByMappingCode,
        loadingError: accountTreeLoadingError,
        rootAccount,
    } = useGetCrosstabAccountTree();

    const { data: clientModes } = useGetClientModes();

    const selectedAttribute =
        groupableAttributes.find((e) => e.dataIndex === attributeSelection)
            ?.title ?? null;

    const resetState = useCallback(() => {
        if (
            crosstabRows !== null &&
            rootAccount !== null &&
            crosstabRows.length > 0
        ) {
            setDataStore(
                getDataSource(
                    crosstabRows,
                    rootAccount,
                    selectedAttribute,
                    isFieldVisible,
                    modeSelection,
                    clientModes ?? [],
                ),
            );
        }
    }, [crosstabRows, rootAccount]);

    const getAttributeSelection = (
        attributeSelection: string | null,
        entity: Entity,
    ) => {
        if (!attributeSelection) {
            return 'Unassigned';
        }
        const entityAttributeSelection = entity[attributeSelection];
        if (attributeSelection && Array.isArray(entityAttributeSelection)) {
            return entityAttributeSelection.join(', ');
        }
        return typeof entity[attributeSelection] === 'string'
            ? (entity[attributeSelection] as string)
            : 'Unassigned';
    };

    useEffect(() => {
        const entities = entityAttributesContext?.data?.entities;
        if (!entities?.length) {
            return;
        }

        if (!attributeSelection) {
            setAttributeSelection(groupableAttributes[0].dataIndex);
        }

        const namesByEntityCode: Dictionary<string> = {};
        const attributesByEntityCode: Dictionary<string> = {};

        for (const entity of entities) {
            if (
                typeof entity.name === 'string' &&
                typeof entity.entity_code === 'string'
            ) {
                namesByEntityCode[entity.entity_code] = entity.name;
                attributesByEntityCode[entity.entity_code] =
                    getAttributeSelection(attributeSelection, entity);
            }
        }

        if (isEqual(attributesByEntityCode, groupableAttributesByEntityCode)) {
            return;
        }
        setGroupableAttributesByEntityCode(attributesByEntityCode);

        if (
            rootAccount !== null &&
            crosstabAccountData !== null &&
            propertyNamesByEntityCode !== null
        ) {
            const expandedRows = getExpandedCrosstabRows(
                rootAccount,
                crosstabAccountData,
                propertyNamesByEntityCode,
                attributesByEntityCode,
                modeSelection,
            );
            setCrosstabRows(expandedRows);
            setDataStore(
                getDataSource(
                    expandedRows,
                    rootAccount,
                    selectedAttribute,
                    isFieldVisible,
                    modeSelection,
                    clientModes ?? [],
                ),
            );
        }

        if (isEqual(namesByEntityCode, propertyNamesByEntityCode)) {
            return;
        }

        setPropertyNamesByEntityCode(namesByEntityCode);
    }, [
        entityAttributesContext,
        propertyNamesByEntityCode,
        groupableAttributesByEntityCode,
        groupableAttributes,
        attributeSelection,
    ]);

    const selectedDataLevel = useSelectedDataLevel();

    useEffect(() => {
        setIsLoading(true);

        if (
            rootAccount === null ||
            propertyNamesByEntityCode === null ||
            groupableAttributesByEntityCode === null ||
            (!asOfDate && !periodRange)
        ) {
            return;
        }

        // never load when 0 entities are selected
        if (entityCodes.length === 0) {
            return;
        }

        const loadData = async () => {
            try {
                const dateRange = periodRange ?? asOfDate;
                if (!dateRange) {
                    console.error(
                        'dateRange was null when attempting to load data',
                    );
                    return;
                }

                const [periodStart, periodEnd] = dateRange;

                setIsLoading(true);

                const rows = await getCrosstabData({
                    entity_codes: entityCodes,
                    period: [
                        periodStart.format('YYYY-MM-DD'),
                        periodEnd.format('YYYY-MM-DD'),
                    ],
                    selectedDataLevel,
                    modeSelection,
                });

                setCrosstabAccountData(rows);
                const expandedRows = getExpandedCrosstabRows(
                    rootAccount,
                    rows,
                    propertyNamesByEntityCode,
                    groupableAttributesByEntityCode,
                    modeSelection,
                );

                setCrosstabRows(expandedRows);
                setDataStore(
                    getDataSource(
                        expandedRows,
                        rootAccount,
                        selectedAttribute,
                        isFieldVisible,
                        modeSelection,
                        clientModes ?? [],
                    ),
                );
            } catch (e) {
                console.error('failed to load crosstab data', e);
                setLoadingError('Crosstab had an issue loading data');
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, [
        asOfDate,
        rootAccount,
        entityCodes,
        periodRange,
        modeSelection,
        propertyNamesByEntityCode,
        selectedDataLevel.stakeholder,
        selectedDataLevel.percentageType,
    ]);

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.Crosstab}
            showDisabledView={true}
        >
            <CrosstabCard
                modeSelection={modeSelection}
                setModeSelection={setModeSelection}
                accountNamesByMappingCode={accountNamesByMappingCode}
                setIsOpenSettings={setIsOpenSettings}
                isOpenSettings={isOpenSettings}
                dataSource={dataStore}
                isLoading={isLoading}
                resetState={resetState}
                rootAccount={rootAccount}
                attributeSelection={attributeSelection}
                setAttributeSelection={setAttributeSelection}
                selectedAttribute={selectedAttribute}
                isFieldVisible={isFieldVisible}
                setIsFieldVisible={setIsFieldVisible}
                periodRange={periodRange}
                setPeriodRange={setPeriodRange}
            />
        </PermissionedWrapper>
    );
};

export default CrosstabContainer;
