import React from 'react';
import useSWR from 'swr';
import {
    getAttributes,
    getGroupableAndFilterableAttributes,
} from 'waypoint-requests';
import { EntityAttributesContext } from './EntityAttributesContext';
import {
    EntityAttributesProps,
    EntityAttributesContextData,
} from './EntityAttributesTypes';

const useAttributes = (): {
    isLoading: boolean;
    isNetworkError: boolean;
    attributes: EntityAttributesContextData | null;
    mutateProviderAttributes: () => void;
} => {
    const {
        data: attributes,
        error: attributesError,
        mutate: mutateAttributes,
    } = useSWR('/api/attributes', getAttributes, {
        revalidateOnFocus: false,
        revalidateOnMount: true,
    });

    const {
        data: groupableAndFilterableAttributes,
        error: groupableAndFilterableError,
        mutate: mutateGroupableAndFilterableAttributes,
    } = useSWR(
        '/api/attributes/groupable-filterable',
        getGroupableAndFilterableAttributes,
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        },
    );

    const mutateProviderAttributes = () => {
        mutateAttributes();
        mutateGroupableAndFilterableAttributes();
    };

    return {
        isLoading: !attributes || !groupableAndFilterableAttributes,
        isNetworkError: !!(attributesError ?? groupableAndFilterableError),
        attributes:
            attributes && groupableAndFilterableAttributes
                ? {
                      ...attributes,
                      groupableAndFilterableAttributes,
                  }
                : null,
        mutateProviderAttributes,
    };
};

export const EntityAttributesProvider = (
    props: EntityAttributesProps,
): JSX.Element => {
    const {
        attributes,
        isLoading: isAttributesLoading,
        isNetworkError,
        mutateProviderAttributes,
    } = useAttributes();

    return (
        <EntityAttributesContext.Provider
            value={{
                data: attributes,
                isAttributesLoading,
                isAttributesNetworkError: isNetworkError,
                mutateProviderAttributes,
            }}
        >
            {props.children}
        </EntityAttributesContext.Provider>
    );
};
