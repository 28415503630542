import React, { useEffect, useRef, useState } from 'react';
import DataGrid, {
    Summary,
    TotalItem,
    Column,
    Format,
    HeaderFilter,
    Paging,
    Pager,
    StateStoring,
    FilterRow,
    FilterPanel,
    ColumnChooser,
    Grouping,
    GroupPanel,
    GroupItem,
    SearchPanel,
    Toolbar,
    Item,
    Scrolling,
    MasterDetail,
    SortByGroupSummaryInfo,
    RemoteOperations,
    DataGridRef,
    ColumnChooserSelection,
    Position,
} from 'devextreme-react/data-grid';
import {
    CustomDataGridStore,
    ExpandAndCollapseButton,
    SortByGroupSummarySortSelect,
} from 'waypoint-react';
import dxDataGrid, {
    InitializedEvent,
    OptionChangedEvent,
    RowPreparedEvent,
} from 'devextreme/ui/data_grid';
import {
    exportExcelFromDataGridWithMasterDetail,
    onContentReady,
} from 'waypoint-utils';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import {
    CellInfoType,
    ChargeSummary,
    RecurringCharge,
    RentRollParams,
    RentRollProps,
    RentRollResult,
    SavedConfiguration,
    SavedConfigurationState,
    SelectedDataLevel,
} from 'waypoint-types';
import { RecurringChargeGrid } from 'components/leases/components/recurring-charge/RecurringChargeGrid';
import { Dropdown, Menu, Tabs } from 'antd';
import {
    getRecurringChargeByRentRoll,
    isUnitOccupied,
} from 'components/leases/components/recurring-charge/utils';
import theme from 'config/theme';
import { RecurringChargesMasterDetail } from 'waypoint-utils/dev-extreme/exportExcelFromDevExtremeDataGrid';
import {
    RENT_ROLL_DEFAULT_COLUMN_SELECTION,
    RentRollColumnSelection,
    rentRollSortByGroupingOptions,
    rentRollWithTotalCharges,
} from './utils';
import { useDataGridHeight, useSortByGroupSummaryInfo } from 'waypoint-hooks';
import { DASH_DASH } from 'config/constants';
import { css } from 'emotion';
import { hasTenantsEnabled, hasUnitMixEnabled } from 'state/user/selectors';
import { connect, RootStateOrAny } from 'react-redux';
import {
    applyStoredConfiguration,
    createSavedConfigurationPayload,
} from 'components/saved-configurations';
import { RentRollExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/RentRollExportableGrid';
import { getRentRoll } from 'waypoint-requests';
import { isEqual } from 'lodash';
import { stringSort } from 'utils/tables/sorters';

const hideClass = css`
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
`;

const pointerEventNone = css`
    pointer-events: none !important;
`;

interface RentRollTableProps {
    rentRoll?: RentRollProps[];
    recurringCharges: RecurringCharge[];
    entityCodes: string[];
    tenantsEnabled: boolean;
    unitMixEnabled: boolean;
    selectedConfiguration: SavedConfiguration | null;
    hidePropertyColumns?: boolean;
    columnSelection?: RentRollColumnSelection;
    setColumnSelection?: (value: RentRollColumnSelection) => void;
    gridConfig?: { [x: string]: any } | null;
    setGridConfig?: (config: { [x: string]: any } | null) => void;
    setLocalConfig?: (config: { [x: string]: any } | null) => void;
    selectedChargeCode?: string[];
    setSelectedChargeCode?: (codes: string[]) => void;
    isPDFExport?: boolean;
    isReportWidget?: boolean;
    selectedDataLevel: SelectedDataLevel;
    summary?: ChargeSummary;
    rentSteps?: RecurringCharge[];
    rentRollTotals?: { data: number[] };
}

const capitalizedCellValue = css`
    text-transform: capitalize;
`;

const RentRollTable = ({
    rentRoll,
    recurringCharges,
    entityCodes,
    tenantsEnabled,
    unitMixEnabled,
    selectedConfiguration,
    hidePropertyColumns,
    columnSelection,
    setColumnSelection,
    gridConfig,
    setGridConfig,
    setLocalConfig,
    selectedChargeCode,
    setSelectedChargeCode,
    isPDFExport,
    isReportWidget,
    summary,
    selectedDataLevel,
    rentSteps,
    rentRollTotals,
}: RentRollTableProps) => {
    const rentRollGrid = useRef<DataGridRef>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [expandButtonEnable, setExpandButtonEnable] = useState<boolean>(true);

    const {
        sortSelection,
        setSortSelection,
        sortOrderAscending,
        setSortOrderAscending,
        sortVisible,
        sortExcludedColumns,
        toggleSortOrder,
        toggleSortSettings,
    } = useSortByGroupSummaryInfo();

    useEffect(() => {
        setLocalConfig &&
            setLocalConfig({
                sortSelection,
                sortOrderAscending,
                expanded,
                selectedChargeCode,
                columnSelection,
            });
    }, []);

    useEffect(() => {
        if (selectedConfiguration?.filters_json?.local_config) {
            const config = selectedConfiguration.filters_json.local_config;
            !!config['sortSelection'] &&
                setSortSelection(config['sortSelection']);
            config['sortOrderAscending'] !== undefined &&
                setSortOrderAscending(config['sortOrderAscending']);
            config['expanded'] !== undefined && setExpanded(config['expanded']);
            !!config['selectedChargeCode'] &&
                setSelectedChargeCode &&
                setSelectedChargeCode(config['selectedChargeCode']);

            if (config['columnSelection'] !== undefined) {
                setColumnSelection &&
                    setColumnSelection(config['columnSelection']);
            }

            if (config['columnSelection'] === undefined) {
                setColumnSelection &&
                    setColumnSelection(RENT_ROLL_DEFAULT_COLUMN_SELECTION);
            }

            return;
        }
        setSortSelection(undefined);
        setSortOrderAscending(true);
        setExpanded(false);
        setSelectedChargeCode && setSelectedChargeCode([]);
    }, [selectedConfiguration]);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const groupCell = (options: CellInfoType) => {
        return <div className={capitalizedCellValue}>{options.value}</div>;
    };

    const onRowPrepared = (e: RowPreparedEvent) => {
        const isGrouped = e.rowType === 'group';
        const isExpanded = e.isExpanded && e.rowType !== 'group';
        const isHeader = e.rowType === 'header';
        if (isGrouped) {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
        if (isExpanded) {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.backgroundColor = theme.colors.blues.focusRow;
            e.rowElement.style.borderColor = theme.colors.grays.background;
        }
        if (isHeader) {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.textDecorationColor = theme.colors.grays.text;
            e.rowElement.style.color = theme.colors.grays.text;
        }
        if (e.rowType === 'data' && !isUnitOccupied(e.data)) {
            const rowElement = e.rowElement.querySelector(
                '.dx-datagrid-group-closed',
            );
            rowElement?.classList.add(hideClass);
            rowElement?.parentElement?.classList.add(pointerEventNone);
        }
    };

    /*
    In the rare but possible case that we have an occupied unit with a null tenant name field,
    set the tenant name to a single blank space so that the row will be counted in the
    total tenant count in the table summary row
    */
    const customizeTenantNameCellValue = (e: RentRollProps) => {
        if (!e.tenant_name && e.space_occupancy_status === 'OCCUPIED') {
            return ' ';
        }
        return e.tenant_name;
    };

    const customizeTenantNameIndustryCellValue = (e: RentRollProps) => {
        return e.tenant_industry ?? DASH_DASH;
    };

    const dataGridHeight = useDataGridHeight({
        topBarHeight: hidePropertyColumns ? 125 : 75,
    });

    const DetailTemplate = ({ data }: any) => {
        const masterDetailData = getRecurringChargeByRentRoll(
            recurringCharges,
            data.data,
        );

        const filteredRentStep = (rentSteps ?? [])
            .filter((item) => {
                return (
                    item.leasable_space_code ===
                        data.data.leasable_space_code &&
                    item.lease_code === data.data.lease_code
                );
            })
            .sort((a, b) => {
                const dateA = a.start_date
                    ? new Date(a.start_date).getTime()
                    : new Date(0).getTime();
                const dateB = b.start_date
                    ? new Date(b.start_date).getTime()
                    : new Date(0).getTime();
                return stringSort(
                    `${b.charge_code}_${dateB}`,
                    `${a.charge_code}_${dateA}`,
                );
            });

        const tabChildren = [
            {
                label: 'Current Charges',
                key: '1',
                children: (
                    <RecurringChargeGrid
                        data={masterDetailData}
                        height={'auto'}
                        isMasterDetail={true}
                    />
                ),
            },
            {
                label: 'Rent Steps',
                key: '2',
                children: (
                    <RecurringChargeGrid
                        data={filteredRentStep}
                        isRentSteps={true}
                        height={'auto'}
                    />
                ),
            },
        ];

        return (
            <div
                style={{
                    padding: '10px',
                }}
            >
                <div
                    style={{
                        display: 'block',
                        fontSize: '13',
                        fontWeight: '600',
                        marginBottom: '10px',
                        lineHeight: '1.25',
                        marginRight: '5%',
                        marginLeft: '5%',
                    }}
                >
                    <Tabs
                        tabPosition="top"
                        size="small"
                        items={tabChildren}
                        tabBarExtraContent={
                            <p>
                                Unit #{data.data.space_number} -{' '}
                                {data.data.tenant_name}{' '}
                            </p>
                        }
                    />
                </div>
            </div>
        );
    };

    // const getRentRollGrid = (ref: DataGridRef<any, any> | null): void => {
    //     setRentRollGrid(ref);
    // };

    const saveState = (state: SavedConfigurationState) => {
        if (setGridConfig) {
            const config = createSavedConfigurationPayload(state);
            if (isEqual(config, gridConfig)) {
                return;
            }
            config && setGridConfig(config);
        }
    };

    const selectedViewIsChargeGroup =
        columnSelection === RentRollColumnSelection.ChargeGroup;

    if (isPDFExport && rentRoll) {
        return (
            <RentRollExportableGrid
                key={selectedConfiguration?.id ?? ''}
                rentRoll={rentRollWithTotalCharges(rentRoll, recurringCharges)}
                savedConfig={selectedConfiguration}
                tenantsEnabled={tenantsEnabled}
                unitMixEnabled={unitMixEnabled}
                selectedViewIsChargeGroup={selectedViewIsChargeGroup}
                sortSelection={sortSelection}
                sortOrderAscending={sortOrderAscending}
            />
        );
    }

    // For report widget using CustomDataGridStore will make the export pdf slower
    const rentRollDataSource = CustomDataGridStore<
        RentRollParams,
        RentRollResult
    >(
        getRentRoll,
        {
            entityCodes,
            selectedDataLevel,
        },
        {
            summary: summary?.bucketTotals,
            charges: recurringCharges,
            totals: rentRollTotals?.data,
            selectedChargeCode,
        },
    );

    return (
        <DataGrid
            repaintChangesOnly={true}
            ref={rentRollGrid}
            wordWrapEnabled={false}
            hoverStateEnabled={true}
            dataSource={rentRollDataSource}
            allowColumnReordering={!isReportWidget}
            height={dataGridHeight}
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}
            columnMinWidth={130}
            data-testid="rent-roll-table"
            onContentReady={(e) =>
                onContentReady({ e, toggleFunc: setExpandButtonEnable })
            }
            onRowPrepared={onRowPrepared}
            onOptionChanged={(e: OptionChangedEvent) => {
                if (e.name !== 'hoveredElement') {
                    toggleSortSettings(e);
                }
            }}
            onInitialized={(e: InitializedEvent) => {
                if (selectedConfiguration && e?.component) {
                    const hiddenColumns = !hidePropertyColumns
                        ? ['property_name', 'entity_display_code']
                        : [];
                    applyStoredConfiguration(
                        e.component,
                        selectedConfiguration,
                        hiddenColumns,
                    );
                }
            }}
        >
            <RemoteOperations filtering grouping paging sorting summary />
            <MasterDetail enabled={true} component={DetailTemplate} />
            <Column
                fixed
                dataField="property_name"
                caption="Property"
                width={'auto'}
                minWidth={180}
                fixedPosition="left"
                visible={!hidePropertyColumns}
                groupCellRender={groupCell}
            />
            <Column
                allowHeaderFiltering
                dataField="entity_display_code"
                caption="Property Code"
                alignment="center"
                groupCellRender={groupCell}
                visible={!hidePropertyColumns}
            />
            <Column
                caption="Unit Information"
                alignment={'center'}
                name="unit_information"
            >
                <Column
                    dataField="space_number"
                    caption="Unit #"
                    allowHeaderFiltering={!isReportWidget}
                    allowGrouping={!isReportWidget}
                    alignment="center"
                />
                {unitMixEnabled ? (
                    <Column
                        dataField="bedroom_count"
                        caption="Beds"
                        allowHeaderFiltering={!isReportWidget}
                        allowGrouping={!isReportWidget}
                        alignment="center"
                        visible={false}
                    />
                ) : null}
                <Column
                    dataField="rentable_sq_ft"
                    caption="Rentable SF"
                    allowHeaderFiltering={false}
                    allowGrouping={false}
                    alignment="center"
                >
                    <Format type="fixedPoint" precision={0} />
                </Column>
                <Column
                    dataField="space_occupancy_status"
                    caption="Status"
                    allowHeaderFiltering={!isReportWidget}
                    groupCellRender={groupCell}
                    alignment="center"
                    allowGrouping={!isReportWidget}
                />
            </Column>
            <Column
                caption="Lease Information"
                alignment={'center'}
                name="lease_information"
            >
                <Column
                    dataField="lease_code"
                    caption="Lease Code"
                    allowHeaderFiltering={false}
                    allowGrouping={false}
                    visible={false}
                    showInColumnChooser={false}
                />
                <Column
                    dataField="source_system_lease_code"
                    caption="Lease Code"
                    allowHeaderFiltering={!isReportWidget}
                    allowGrouping={!isReportWidget}
                    groupCellRender={groupCell}
                    alignment="center"
                />
                <Column
                    dataField="tenant_name"
                    caption="Tenant"
                    calculateCellValue={(e: RentRollProps) =>
                        customizeTenantNameCellValue(e)
                    }
                    width={'auto'}
                    minWidth={160}
                    allowHeaderFiltering={!isReportWidget}
                    allowGrouping={!isReportWidget}
                    groupCellRender={groupCell}
                />
                {tenantsEnabled ? (
                    <Column
                        dataField="tenant_industry"
                        caption="Industry"
                        width={'auto'}
                        minWidth={160}
                        allowHeaderFiltering={!isReportWidget}
                        groupCellRender={groupCell}
                        allowGrouping={!isReportWidget}
                        calculateCellValue={(e: RentRollProps) =>
                            customizeTenantNameIndustryCellValue(e)
                        }
                        cssClass={capitalizedCellValue}
                    />
                ) : null}
                <Column
                    dataField="lease_type"
                    caption="Type"
                    allowHeaderFiltering={!isReportWidget}
                    allowGrouping={!isReportWidget}
                    groupCellRender={groupCell}
                    alignment="center"
                >
                    <Format type="fixedPoint" />
                </Column>
            </Column>
            <Column caption="Term" alignment={'center'} name="term">
                <Column
                    dataField="lease_start_date"
                    caption="Start"
                    allowHeaderFiltering={false}
                    dataType="date"
                    allowGrouping={false}
                    alignment="center"
                >
                    <Format type="shortDate" />
                </Column>
                <Column
                    dataField="lease_expiration_date"
                    caption="End"
                    allowHeaderFiltering={false}
                    dataType="date"
                    allowGrouping={false}
                    alignment="center"
                >
                    <Format type="shortDate" />
                </Column>
                <Column
                    dataField="expiration_year"
                    caption="Exp. Year"
                    allowHeaderFiltering={!isReportWidget}
                    groupCellRender={groupCell}
                    alignment="center"
                    allowGrouping={!isReportWidget}
                />
                <Column
                    dataField="lease_term_months"
                    caption="Term Mos."
                    allowHeaderFiltering={false}
                    allowGrouping={false}
                    alignment="center"
                >
                    <Format type="fixedPoint" precision={0} />
                </Column>

                <Column
                    dataField="remaining_months"
                    caption="Mos. Remaining"
                    allowHeaderFiltering={false}
                    allowGrouping={false}
                    alignment="center"
                >
                    <Format type="fixedPoint" precision={0} />
                </Column>
            </Column>
            {selectedViewIsChargeGroup && (
                <Column caption="Rent" alignment={'center'} name="rent">
                    <Column
                        dataField="rent_monthly"
                        caption="Monthly"
                        allowHeaderFiltering={false}
                        allowGrouping={false}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="rent_monthly_per_sq_ft"
                        caption="Monthly / SF"
                        visible={true}
                        allowHeaderFiltering={false}
                        allowGrouping={false}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="rent_annual"
                        caption="Annual"
                        allowHeaderFiltering={false}
                        allowGrouping={false}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="rent_annual_per_sq_ft"
                        caption="Annual / SF"
                        allowHeaderFiltering={false}
                        allowGrouping={!isReportWidget}
                        groupCellRender={groupCell}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                </Column>
            )}
            {selectedViewIsChargeGroup && (
                <Column caption="CAM" alignment={'center'} name="cam">
                    <Column
                        dataField="cam_monthly"
                        caption="Monthly"
                        allowHeaderFiltering={false}
                        allowGrouping={false}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="cam_monthly_per_sq_ft"
                        caption="Monthly / SF"
                        allowHeaderFiltering={false}
                        allowGrouping={false}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="cam_annual"
                        caption="Annual"
                        allowHeaderFiltering={false}
                        allowGrouping={false}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="cam_annual_per_sq_ft"
                        caption="Annual / SF"
                        allowHeaderFiltering={false}
                        allowGrouping={!isReportWidget}
                        groupCellRender={groupCell}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                </Column>
            )}
            {selectedViewIsChargeGroup && (
                <Column
                    caption="Taxes & Insurance"
                    alignment={'center'}
                    name="taxes_and_insurance"
                >
                    <Column
                        dataField="taxes_insurance_monthly"
                        caption="Monthly"
                        allowHeaderFiltering={false}
                        allowGrouping={false}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="taxes_insurance_monthly_per_sq_ft"
                        caption="Monthly / SF"
                        allowHeaderFiltering={false}
                        allowGrouping={false}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="taxes_insurance_annual"
                        caption="Annual"
                        allowHeaderFiltering={false}
                        allowGrouping={false}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="taxes_insurance_annual_per_sq_ft"
                        caption="Annual / SF"
                        allowHeaderFiltering={false}
                        allowGrouping={!isReportWidget}
                        groupCellRender={groupCell}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                </Column>
            )}
            {selectedViewIsChargeGroup && (
                <Column caption="Other" alignment={'center'} name="other">
                    <Column
                        dataField="other_monthly"
                        caption="Monthly"
                        allowHeaderFiltering={false}
                        allowGrouping={false}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="other_monthly_per_sq_ft"
                        caption="Monthly / SF"
                        allowHeaderFiltering={false}
                        allowGrouping={false}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="other_annual"
                        caption="Annual"
                        allowHeaderFiltering={false}
                        allowGrouping={false}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="other_annual_per_sq_ft"
                        caption="Annual / SF"
                        allowHeaderFiltering={false}
                        allowGrouping={!isReportWidget}
                        groupCellRender={groupCell}
                        alignment="center"
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                </Column>
            )}
            <Column
                caption="Total Charges"
                alignment={'center'}
                name="total_charges"
            >
                <Column
                    dataField="total_monthly"
                    caption="Monthly"
                    allowHeaderFiltering={false}
                    allowGrouping={false}
                    alignment="center"
                >
                    <Format type="currency" precision={2} />
                </Column>
                <Column
                    dataField="total_monthly_per_sq_ft"
                    caption="Monthly / SF"
                    visible={true}
                    allowHeaderFiltering={false}
                    allowGrouping={false}
                    alignment="center"
                >
                    <Format type="currency" precision={2} />
                </Column>
                <Column
                    dataField="total_annual"
                    caption="Annual"
                    allowHeaderFiltering={false}
                    allowGrouping={false}
                    alignment="center"
                >
                    <Format type="currency" precision={2} />
                </Column>
                <Column
                    dataField="total_annual_per_sq_ft"
                    caption="Annual / SF"
                    allowHeaderFiltering={false}
                    allowGrouping={!isReportWidget}
                    groupCellRender={groupCell}
                    alignment="center"
                >
                    <Format type="currency" precision={2} />
                </Column>
            </Column>
            <Summary>
                <GroupItem
                    name="total_units"
                    column="leasable_space_code"
                    summaryType="custom"
                    showInColumn="space_number"
                    displayFormat="{0} Units"
                    alignByColumn
                />
                <GroupItem
                    column="lease_code"
                    name="group_source_system_lease_code"
                    showInColumn="source_system_lease_code"
                    summaryType="custom"
                    displayFormat="{0} Leases"
                    alignByColumn
                />
                <GroupItem
                    column="tenant_name"
                    name="group_tenants"
                    summaryType="custom"
                    displayFormat="{0} Tenants"
                    alignByColumn
                />
                <GroupItem
                    column="property_name"
                    name="group_properties"
                    summaryType="custom"
                    displayFormat="{0} Properties"
                    alignByColumn
                />
                <GroupItem
                    name="rentable_sq_ft"
                    column="rentable_sq_ft"
                    showInColumn="rentable_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'fixedPoint', precision: 0 }}
                    alignByColumn
                    displayFormat="{0}"
                />
                <GroupItem
                    name="group_lease_term_months"
                    column="lease_term_months"
                    summaryType="avg"
                    valueFormat={{ type: 'fixedPoint', precision: 1 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    name="remaining_months"
                    column="remaining_months"
                    showInColumn="remaining_months"
                    summaryType="custom"
                    valueFormat={{ type: 'fixedPoint', precision: 1 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    name="total_monthly_per_sq_ft"
                    column="total_monthly_per_sq_ft"
                    showInColumn="total_monthly_per_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    alignByColumn
                    displayFormat="{0}"
                />
                <GroupItem
                    name="group_total_annual"
                    column="total_annual"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    alignByColumn
                    displayFormat="{0}"
                />
                <GroupItem
                    name="total_annual_per_sq_ft"
                    column="total_annual_per_sq_ft"
                    showInColumn="total_annual_per_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    alignByColumn
                    displayFormat="{0}"
                />
                <GroupItem
                    name="group_total_monthly"
                    column="total_monthly"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    alignByColumn
                    displayFormat="{0}"
                />
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_rent_monthly"
                        column="rent_monthly"
                        alignByColumn
                        summaryType="sum"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_rent_monthly_per_sq_ft"
                        column="rent_monthly_per_sq_ft"
                        showInColumn="rent_monthly_per_sq_ft"
                        alignByColumn
                        summaryType="custom"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_rent_annual"
                        column="rent_annual"
                        alignByColumn
                        summaryType="sum"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_rent_annual_per_sq_ft"
                        showInColumn="rent_annual_per_sq_ft"
                        column="rent_annual_per_sq_ft"
                        alignByColumn
                        summaryType="custom"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_cam_monthly"
                        column="cam_monthly"
                        alignByColumn
                        summaryType="sum"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_cam_monthly_per_sq_ft"
                        showInColumn="cam_monthly_per_sq_ft"
                        column="cam_monthly_per_sq_ft"
                        alignByColumn
                        summaryType="custom"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_cam_annual"
                        column="cam_annual"
                        alignByColumn
                        summaryType="sum"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_cam_annual_per_sq_ft"
                        showInColumn="cam_annual_per_sq_ft"
                        column="cam_annual_per_sq_ft"
                        alignByColumn
                        summaryType="custom"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_taxes_insurance_monthly"
                        column="taxes_insurance_monthly"
                        alignByColumn
                        summaryType="sum"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_taxes_insurance_monthly_per_sq_ft"
                        showInColumn="taxes_insurance_monthly_per_sq_ft"
                        column="taxes_insurance_monthly_per_sq_ft"
                        alignByColumn
                        summaryType="custom"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_taxes_insurance_annual"
                        column="taxes_insurance_annual"
                        alignByColumn
                        summaryType="sum"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_taxes_insurance_annual_per_sq_ft"
                        showInColumn="taxes_insurance_annual_per_sq_ft"
                        column="taxes_insurance_annual_per_sq_ft"
                        alignByColumn
                        summaryType="custom"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_other_monthly"
                        column="other_monthly"
                        alignByColumn
                        summaryType="sum"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_other_monthly_per_sq_ft"
                        showInColumn="other_monthly_per_sq_ft"
                        column="other_monthly_per_sq_ft"
                        alignByColumn
                        summaryType="custom"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_other_annual"
                        column="other_annual"
                        alignByColumn
                        summaryType="sum"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                {selectedViewIsChargeGroup && (
                    <GroupItem
                        name="group_other_annual_per_sq_ft"
                        showInColumn="other_annual_per_sq_ft"
                        column="other_annual_per_sq_ft"
                        alignByColumn
                        summaryType="custom"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                )}
                <TotalItem
                    name="total_property_name"
                    column="property_name"
                    summaryType="custom"
                    valueFormat={{ type: 'fixedPoint', precision: 0 }}
                    displayFormat="{0} Properties"
                />

                {unitMixEnabled ? (
                    <TotalItem
                        name="total_beds"
                        column="bedroom_count"
                        summaryType="sum"
                        displayFormat="{0} Beds"
                    />
                ) : null}
                <TotalItem
                    name="total_leases"
                    column="lease_code"
                    summaryType="custom"
                    showInColumn="source_system_lease_code"
                    valueFormat={{ type: 'fixedPoint', precision: 0 }}
                    displayFormat="{0} Leases"
                />
                <TotalItem
                    name="total_tenants"
                    column="tenant_name"
                    summaryType="custom"
                    valueFormat={{ type: 'fixedPoint', precision: 0 }}
                    displayFormat="{0} Tenants"
                />
                <TotalItem
                    name="total_units"
                    column="leasable_space_code"
                    summaryType="custom"
                    showInColumn="space_number"
                    valueFormat={{ type: 'fixedPoint', precision: 0 }}
                    displayFormat="{0} Units"
                />
                <TotalItem
                    column="lease_term_months"
                    summaryType="avg"
                    valueFormat={{ type: 'fixedPoint', precision: 1 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="remaining_months"
                    column="remaining_months"
                    showInColumn="remaining_months"
                    summaryType="custom"
                    valueFormat={{ type: 'fixedPoint', precision: 1 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="rentable_sq_ft"
                    showInColumn="rentable_sq_ft"
                    column="rentable_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'fixedPoint', precision: 0 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_rent_monthly"
                    column="rent_monthly"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_rent_monthly_per_sq_ft"
                    showInColumn="rent_monthly_per_sq_ft"
                    column="rent_monthly_per_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_rent_annual"
                    column="rent_annual"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_rent_annual_per_sq_ft"
                    showInColumn="rent_annual_per_sq_ft"
                    column="rent_annual_per_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_cam_monthly"
                    column="cam_monthly"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_cam_monthly_per_sq_ft"
                    showInColumn="cam_monthly_per_sq_ft"
                    column="cam_monthly_per_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_cam_annual"
                    column="cam_annual"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_cam_annual_per_sq_ft"
                    showInColumn="cam_annual_per_sq_ft"
                    column="cam_annual_per_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_taxes_insurance_monthly"
                    column="taxes_insurance_monthly"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_taxes_insurance_monthly_per_sq_ft"
                    showInColumn="taxes_insurance_monthly_per_sq_ft"
                    column="taxes_insurance_monthly_per_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_taxes_insurance_annual"
                    column="taxes_insurance_annual"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_taxes_insurance_annual_per_sq_ft"
                    showInColumn="taxes_insurance_annual_per_sq_ft"
                    column="taxes_insurance_annual_per_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_other_monthly"
                    column="other_monthly"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_other_monthly_per_sq_ft"
                    showInColumn="other_monthly_per_sq_ft"
                    column="other_monthly_per_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_other_annual"
                    column="other_annual"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_other_annual_per_sq_ft"
                    showInColumn="other_annual_per_sq_ft"
                    column="other_annual_per_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    column="total_monthly"
                    summaryType={'sum'}
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    column="total_annual"
                    summaryType={'sum'}
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_monthly_per_sq_ft"
                    showInColumn="total_monthly_per_sq_ft"
                    column="total_monthly_per_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    name="total_annual_per_sq_ft"
                    showInColumn="total_annual_per_sq_ft"
                    column="total_annual_per_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
            </Summary>
            <SortByGroupSummaryInfo
                summaryItem={sortSelection}
                sortOrder={sortOrderAscending ? 'asc' : 'desc'}
            />
            <HeaderFilter
                allowSelectAll={true}
                visible={true}
                allowSearch={true}
                height={350}
            />
            <FilterRow visible={!isReportWidget} applyFilter="auto" />
            <FilterPanel visible={!isReportWidget} />
            <Grouping
                contextMenuEnabled={!isReportWidget}
                autoExpandAll={expanded}
            />
            <GroupPanel visible={isReportWidget ? false : 'auto'} />
            <ColumnChooser
                enabled={!isReportWidget}
                mode={'select'}
                height={450}
                allowSearch={true}
            >
                <Position
                    my="right top"
                    at="right bottom"
                    of=".dx-datagrid-column-chooser-button"
                />
                <ColumnChooserSelection
                    allowSelectAll={true}
                    recursive={true}
                />
            </ColumnChooser>
            <Toolbar>
                <Item location="after">
                    <Dropdown
                        overlay={exportExcelMenu(
                            rentRollGrid?.current?.instance(),
                            recurringCharges,
                            entityCodes.length <= 1,
                            rentSteps,
                        )}
                    >
                        <i
                            className="dx-icon dx-icon-xlsxfile"
                            style={{ fontSize: '18px', marginTop: '3px' }}
                        ></i>
                    </Dropdown>
                </Item>
                <Item location="after" name="columnChooserButton" />
                <Item name="searchPanel" locateInMenu="auto" />
                <Item name="groupPanel" />
                <Item location="before" visible={!isReportWidget}>
                    <ExpandAndCollapseButton
                        expanded={expanded}
                        toggleExpanded={toggleExpanded}
                        expandButtonEnable={expandButtonEnable}
                    />
                </Item>
                <Item
                    location="before"
                    visible={sortVisible && !isReportWidget}
                >
                    <SortByGroupSummarySortSelect
                        groupingOptions={rentRollSortByGroupingOptions}
                        sortExcludedColumns={sortExcludedColumns}
                        sortSelection={sortSelection}
                        setSortSelection={setSortSelection}
                        sortOrderAscending={sortOrderAscending}
                        toggleSortOrder={toggleSortOrder}
                    />
                </Item>
            </Toolbar>
            <SearchPanel
                visible={true}
                highlightCaseSensitive={false}
                width={250}
            />
            <Paging enabled={true} defaultPageSize={60} />
            <Scrolling mode="infinite" />
            <Pager
                visible
                displayMode={'full'}
                showPageSizeSelector={true}
                allowedPageSizes={[20, 40, 60, 80, 100]}
                showNavigationButtons={true}
                showInfo={true}
                infoText="Page {0} of {1} ({2} items)"
            />
            <StateStoring
                enabled={!isReportWidget}
                type="custom"
                savingTimeout={100}
                customSave={saveState}
            />
        </DataGrid>
    );
};

const exportExcelMenu = (
    component: dxDataGrid | undefined,
    recurringCharges: RecurringCharge[],
    allowExportCharges: boolean,
    rentSteps?: RecurringCharge[],
) => {
    const detailHeaders = [
        {
            key: 'start_date',
            name: 'Start Date',
            dataType: 'date',
        },
        {
            key: 'end_date',
            name: 'End Date',
            dataType: 'date',
        },
        { key: 'charge_name', name: 'Charge' },
        {
            key: 'monthly',
            name: 'Monthly',
            dataType: 'currency',
        },
        {
            key: 'annual',
            name: 'Annual',
            dataType: 'currency',
        },
        {
            key: 'monthly_per_sq_ft',
            name: 'Monthly / SF',
            dataType: 'currency',
        },
        {
            key: 'annual_per_sq_ft',
            name: 'Annual / SF',
            dataType: 'currency',
        },
    ];

    return (
        <Menu>
            <Menu.Item
                key={'rent-roll'}
                onClick={() =>
                    exportExcelFromDataGridWithMasterDetail({
                        worksheetName: 'Rent Roll Summary',
                        filename: 'Rent_Roll_Summary.xlsx',
                        component: component,
                    })
                }
            >
                Export Summary
            </Menu.Item>
            {allowExportCharges ? (
                <>
                    <Menu.Item
                        key={'rent-roll-detail-grid'}
                        onClick={() =>
                            exportExcelFromDataGridWithMasterDetail({
                                worksheetName: 'Rent Roll with Charges',
                                filename: 'Rent_Roll_Charges.xlsx',
                                component: component,
                                firstColumName: 'space_number',
                                masterDetailType: RecurringChargesMasterDetail,
                                masterDetailData: recurringCharges,
                                masterDetailHeaderColumns: detailHeaders,
                            })
                        }
                    >
                        Export w/ Charges
                    </Menu.Item>
                    <Menu.Item
                        key={'rent-roll-detail-grid-with-steps'}
                        onClick={() =>
                            exportExcelFromDataGridWithMasterDetail({
                                worksheetName: 'Rent Roll with Rent Steps',
                                filename: 'Rent_Roll_Rent_Steps.xlsx',
                                component: component,
                                firstColumName: 'space_number',
                                masterDetailType: RecurringChargesMasterDetail,
                                masterDetailData: rentSteps ?? [],
                                masterDetailHeaderColumns: detailHeaders,
                            })
                        }
                    >
                        Export w/ Rent Steps
                    </Menu.Item>
                </>
            ) : null}
        </Menu>
    );
};

const mapState = (state: RootStateOrAny) => {
    const tenantsEnabled = hasTenantsEnabled(state);
    const unitMixEnabled = hasUnitMixEnabled(state);

    return {
        tenantsEnabled,
        unitMixEnabled,
    };
};

export const MemoizedRentRollTable = React.memo(
    connect(mapState)(RentRollTable),
);
